import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import {
  distinctUntilChanged,
  filter,
  Observable,
  of,
  startWith,
  Subscription,
} from 'rxjs';
import { BreadcrumbItemClickEvent } from 'primeng/breadcrumb';
import { StorageService } from '../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-breadcrumb-container',
  templateUrl: './breadcrumb-container.component.html',
  styleUrl: './breadcrumb-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbContainerComponent implements OnInit, OnDestroy {

  items: MenuItem[];
  item$: Observable<MenuItem[]>;
  hideFlag: boolean;
  subscription: Subscription = new Subscription();

  constructor(private router: Router, private cdRef: ChangeDetectorRef,
    public storageService: StorageService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.setDefaultBreadcrumb();

    this.subscription.add(
      this.router.events
        .pipe(
          filter((e): e is NavigationEnd => e instanceof NavigationEnd),
          distinctUntilChanged(),
          startWith(this.router.url)
        )
        .subscribe((event: string | NavigationEnd) => {
          let url = typeof event === 'string' ? event : event.url;
          this.getBreadcrumbUrl(url);
        })
    );
  }

  setOb$(items: MenuItem[]) {
    items?.map((x: any) => {
      x.label = x.label?.replace(/\s/g, "");
      return x
    })
    this.item$ = of(items);
  }

  setDefaultBreadcrumb() {
    this.items = [];
    let homeObj = { label: 'Home', routerLink: AppConstants.routeUrls.home };
    this.items.push(homeObj);

    this.setOb$(this.items);
    this.cdRef.markForCheck();
  }

  getBreadcrumbUrl(currentUrl: string) {
    this.hideFlag = false;
    let labelVal = this.getLabelValue(currentUrl);

    if (labelVal?.length > 0) {
      let obj: MenuItem = { label: labelVal, routerLink: currentUrl };
      this.appendBreadcrumb(obj);
    } else {
      let patternVal = this.checkRegexPattern(currentUrl);
      if (patternVal?.length > 0) {
        let obj: MenuItem = { label: patternVal, routerLink: currentUrl };
        this.appendBreadcrumb(obj);
      } else {
        this.setDefaultBreadcrumb();
        this.hideFlag = true;
      }
    }
  }

  getLabelValue(currentUrl: string): string {
    let label: string;

    let strVal = Object.keys(AppConstants.breadcrumbUrls).find((key) => currentUrl.includes(key));
    label = strVal ? AppConstants.breadcrumbUrls[strVal] : null;

    return label;
  }

  checkRegexPattern(currentUrl: string) {
    let str: string = null;
    let productDetailsRegex: RegExp = new RegExp(/\/p\/[a-zA-Z0-9]+(?:_N|_R)?/, 'gi');
    if (productDetailsRegex.test(currentUrl)) {
      str = AppConstants.breadcrumbUrls['product-details'];
    }
    return str;
  }

  appendBreadcrumb(obj: MenuItem) {
    let exceptionUrl = this.getExceptionUrl(obj);
    if (exceptionUrl?.length > 0) {
      this.handleExceptions(exceptionUrl, obj);
    } else {
      this.items =
        this.items.length > 1
          ? [...this.items.slice(0, 1), obj]
          : [...this.items, obj];
      this.setOb$(this.items);
    }
    this.cdRef.markForCheck();
  }

  getExceptionUrl(obj): string {
    let str: string = null;
    if (obj.routerLink.includes(AppConstants.routeUrls.userManagement)) {
      str = AppConstants.breadcrumbUrls['user-management'];
    } else if (obj.routerLink.includes(AppConstants.routeUrls.orderDetails)) {
      str = AppConstants.breadcrumbUrls['order-details'];
    }
    return str;
  }

  handleExceptions(pageName: string, obj: MenuItem) {
    switch (pageName) {
      case AppConstants.breadcrumbUrls['user-management']:
        let userManagement: MenuItem = {
          label: AppConstants.breadcrumbUrls['user-management'],
          routerLink: AppConstants.routeUrls.accessRequest,
        };
        this.items = [...this.items.slice(0, 1), userManagement, obj];
        this.setOb$(this.items);
        break;
      case AppConstants.breadcrumbUrls['order-details']:
        let orderHistory: MenuItem = {
          label: AppConstants.breadcrumbUrls['order-history'],
          routerLink: AppConstants.routeUrls.orderHistory,
        };
        this.items = [...this.items.slice(0, 1), orderHistory, obj];
        this.setOb$(this.items);
        break;
      default:
        this.setDefaultBreadcrumb();
    }
  }

  onClick(event: BreadcrumbItemClickEvent) {
    let index = this.items.findIndex((item: MenuItem) => item.label === event.item.label);
    this.items = this.items.filter((v, i) => i <= index);
    this.setOb$(this.items);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.globalService.clearMessagesOnDestroy();
  }
}
