<!-- PrimeNG breadcrumb-->
<!-- <p-breadcrumb
    styleClass="cstm-breadcrumb"
    class="max-w-full"
    [ngClass]="{'dNone': hideFlag}"
    [model]="(item$ | async)"
    (onItemClick)="onClick($event)" 
/> -->

<!-- Bootstrap breadcrumb-->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li *ngFor="let item of (item$ | async); last as l" class="breadcrumb-item">
            <ng-container *ngIf="l; else prevNav">
                <a class="breadcrumb-item active secondary-anchor" href="{{item.routerLink}}">{{item.label | cxTranslate}}</a>
            </ng-container>
            <ng-template #prevNav>
                <a class="secondary-anchor" aria-current="page" routerLink="{{item.routerLink}}">
                    {{item.label | cxTranslate}}
                </a>
            </ng-template>
        </li>
    </ol>
</nav>